<template>
  <div class="main-body">
    <div class="table-operator">
      <a-button type="primary" @click="goTo('/goods/brand/add')">添加品牌</a-button>
    </div>
    <a-table class="doc-table" :dataSource="dataSource" :columns="columns" :pagination="false">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'image'">
          <a-avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
        </template>
        <template v-if="column.key === 'sort'">
          <a-input-number v-model:value="record.sort" :min="0" :max="100000" />
        </template>
        <template v-if="column.key === 'is_show'">
          <span v-if="record.is_show == 0"> 显示 </span>
          <span v-else>不显示</span>
        </template>
        <template v-if="column.key === 'action'">
          <a-space>
            <a-button type="link" size="small" @click="goTo('/goods/brand/edit')">编辑</a-button>
            <a-button type="link" size="small" danger>删除</a-button>
          </a-space>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();

    const goTo = (path) => {
      router.push(path);
    };

    onMounted(() => {});

    return {
      goTo,
      dataSource: ref([
        {
          brand_name: "华为",
          image: "",
          sort: 0,
          is_show: 0,
        },
        {
          brand_name: "小米",
          image: "",
          sort: 0,
          is_show: 0,
        },
        {
          brand_name: "苹果",
          image: "",
          sort: 0,
          is_show: 0,
        },
      ]),
      columns: [
        {
          title: "品牌名称",
          dataIndex: "brand_name",
          key: "brand_name",
          width: "30%",
          rowDrag: true,
        },
        {
          title: "品牌LOGO",
          dataIndex: "image",
          key: "image",
          width: "20%",
        },
        {
          title: "排序",
          dataIndex: "sort",
          key: "sort",
          width: "20%",
        },
        {
          title: "是否显示",
          dataIndex: "is_show",
          key: "is_show",
          width: "10%",
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: "20%",
        },
      ],
    };
  },
});
</script>